var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết xin nghỉ: " + _vm.takeLeaveData.kids.fullName,
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "4vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "main-content" }, [
            _c("div", { staticClass: "over-table" }, [
              _c(
                "table",
                { staticClass: "table-data", attrs: { border: "" } },
                [
                  _c("thead", { staticClass: "table-header" }, [
                    _c("tr", [
                      _c("td", { staticClass: "table-index" }, [_vm._v("STT")]),
                      _c("td", { staticClass: "table-item-name" }, [
                        _vm._v("Mục"),
                      ]),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("Chi tiết đơn xin nghỉ"),
                      ]),
                      _vm.checkPermission(["parentDiary_absenLetter_update"])
                        ? _c("td", { staticClass: "table-action" }, [
                            _vm._v("\n                Tác vụ\n              "),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("tbody", { staticClass: "table-body" }, [
                    _c("tr", [
                      _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                      _c("td", { staticClass: "table-item-name" }, [
                        _c("div", [_vm._v("Phụ huynh")]),
                        _c("br"),
                        _c("div", { staticStyle: { color: "#4177e2" } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.takeLeaveData.createdDate
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [
                            _vm._v(
                              "\n                  - Thời gian nghỉ:\n                  " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    _vm.takeLeaveData.fromDate
                                  )
                                ) +
                                " đến\n                  " +
                                _vm._s(
                                  _vm._f("formatDate")(_vm.takeLeaveData.toDate)
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _c("div", [
                          _vm._v(
                            "- Nội dung: " +
                              _vm._s(_vm.takeLeaveData.absentContent)
                          ),
                        ]),
                      ]),
                      _vm.checkPermission(["parentDiary_absenLetter_update"])
                        ? _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: _vm.loadingButtonConfirm,
                                    disabled: _vm.takeLeaveData.confirmStatus
                                      ? true
                                      : false,
                                    type: "danger",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleConfirm()
                                    },
                                  },
                                },
                                [
                                  _vm.takeLeaveData.confirmStatus
                                    ? _c("span", [_vm._v("Đã xác nhận")])
                                    : _c("span", [_vm._v("Xác nhận")]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                      _c("td", { staticClass: "table-item-name" }, [
                        _vm._v("File đính kèm"),
                      ]),
                      _c(
                        "td",
                        [
                          _c("el-upload", {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: "",
                              multiple: "",
                              "auto-upload": false,
                              limit: 5,
                              "file-list":
                                _vm.takeLeaveData.absentLetterAttachFileList,
                              "on-preview": _vm.handleFileRequest,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.checkPermission(["parentDiary_absenLetter_update"])
                        ? _c("td")
                        : _vm._e(),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                      _c("td", { staticClass: "table-item-name" }, [
                        _c("div", [_vm._v("Giáo viên phản hồi")]),
                        _c("div", { staticClass: "color-text-reply" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.takeLeaveData.teacherTimeReply
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c("textarea", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled:
                              _vm.takeLeaveData.teacherReplyDel ||
                              _vm.getAppTypeUserLogin != "teacher",
                            type: "textarea",
                            rows: 4,
                            maxlength: "3000",
                            textarea: "",
                            "show-word-limit": "",
                            placeholder: "Nhập nội dung giáo viên phản hồi",
                          },
                          domProps: { value: _vm.newNameTeacher },
                          on: {
                            keyup: function ($event) {
                              _vm.newNameTeacher = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm.checkPermission(["parentDiary_absenLetter_update"])
                        ? _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !!_vm.takeLeaveData.teacherReply
                                      ? false
                                      : true,
                                    type: "success",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.revokeTeacherMethod()
                                    },
                                  },
                                },
                                [
                                  _vm.takeLeaveData.teacherReplyDel
                                    ? _c("span", [_vm._v("Hủy thu hồi")])
                                    : _c("span", [_vm._v("Thu hồi")]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                      _c("td", { staticClass: "table-item-name" }, [
                        _c("div", [_vm._v("Nhà trường phản hồi")]),
                        _c("div", { staticStyle: { "margin-top": "5px" } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.takeLeaveData.schoolReplyy) +
                              "\n                "
                          ),
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "color-text-reply" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.takeLeaveData.schoolTimeReply
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c("textarea", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled:
                              _vm.takeLeaveData.schoolReplyDel ||
                              _vm.getAppTypeUserLogin != "plus",
                            type: "textarea",
                            rows: 4,
                            maxlength: "3000",
                            textarea: "",
                            "show-word-limit": "",
                            placeholder: "Nhập nội dung nhà trường phản hồi",
                          },
                          domProps: { value: _vm.newName },
                          on: {
                            keyup: function ($event) {
                              _vm.newName = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm.checkPermission(["parentDiary_absenLetter_update"])
                        ? _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _vm.getAppTypeUserLogin == "plus"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: !!this.newName ? false : true,
                                        type: "success",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.revokePlusMethod()
                                        },
                                      },
                                    },
                                    [
                                      _vm.takeLeaveData.schoolReplyDel
                                        ? _c("span", [_vm._v("Hủy thu hồi")])
                                        : _c("span", [_vm._v("Thu hồi")]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _vm.checkPermission(["parentDiary_absenLetter_update"])
                ? _c(
                    "span",
                    [
                      (this.newName != "" &&
                        this.newName != null &&
                        this.newName != _vm.takeLeaveData.schoolReply) ||
                      (this.newNameTeacher != "" &&
                        this.newNameTeacher != null &&
                        this.newNameTeacher != _vm.takeLeaveData.teacherReply)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "medium",
                                loading: _vm.loadingButtonSaved,
                                mini: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSaveTakeLeave()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-check" }),
                              _c("span", [_vm._v("Lưu")]),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: "",
                                type: "success",
                                size: "medium",
                                loading: _vm.loadingButtonSaved,
                                mini: "",
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-check" }),
                              _c("span", [_vm._v("Lưu")]),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }