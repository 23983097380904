import {Message} from 'element-ui';

export default new class NotifyMessage {
    notifyError(message) {
        Message({
            message: message,
            type: "error",
            duration: 6000
        });
    }

    notifySuccess(message) {
        Message({
            message: message,
            type: "success",
            duration: 6000
        });
    }
};

