var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "hasagi" },
        [
          _c("el-date-picker", {
            staticClass: "button-left-class1",
            staticStyle: { width: "160px" },
            attrs: {
              type: "date",
              "value-format": "yyyy-MM-dd",
              format: "dd-MM-yyyy",
              placeholder: "Chọn ngày nghỉ",
            },
            on: {
              change: function ($event) {
                return _vm.searchHeaderMethod()
              },
            },
            model: {
              value: _vm.dataSearch.date,
              callback: function ($$v) {
                _vm.$set(_vm.dataSearch, "date", $$v)
              },
              expression: "dataSearch.date",
            },
          }),
          this.getAppTypeUserLogin == "plus"
            ? _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  attrs: { clearable: "", placeholder: "Chọn khối" },
                  on: {
                    change: function ($event) {
                      return _vm.changeGradeMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.idGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "idGrade", $$v)
                    },
                    expression: "dataSearch.idGrade",
                  },
                },
                _vm._l(_vm.gradeOfSchoolList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.gradeName },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-select",
            {
              staticClass: "button-left-class",
              attrs: { filterable: "", placeholder: "Chọn lớp" },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.idClass,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idClass", $$v)
                },
                expression: "dataSearch.idClass",
              },
            },
            _vm._l(_vm.classList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.className },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "button-left-class",
              attrs: { placeholder: "Trạng thái", clearable: "" },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.confirmStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "confirmStatus", $$v)
                },
                expression: "dataSearch.confirmStatus",
              },
            },
            _vm._l(_vm.confirmStatus, function (item) {
              return _c("el-option", {
                key: item.status,
                attrs: { value: item.status, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "button-left-class",
              attrs: { placeholder: "Tình trạng", clearable: "" },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.dateSick,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "dateSick", $$v)
                },
                expression: "dataSearch.dateSick",
              },
            },
            _vm._l(_vm.dateSick, function (item) {
              return _c("el-option", {
                key: item.status,
                attrs: { value: item.status, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticClass: "button-left-class",
              staticStyle: { width: "300px" },
              attrs: {
                placeholder: "Nhập tên, nội dung tìm kiếm",
                clearable: "",
              },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.name,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "name", $$v)
                },
                expression: "dataSearch.name",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm.checkPermission(["parentDiary_absenLetter_update"])
            ? _c(
                "div",
                { staticClass: "buttondropdown" },
                [
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommand } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-over",
                          attrs: { type: "success" },
                        },
                        [
                          _vm._v("\n          Tác vụ\n          "),
                          _c("i", { staticClass: "el-icon-caret-bottom" }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { staticClass: "el-dropdown-menu-container" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "handleRead" } },
                            [_vm._v("Duyệt đã đọc\n          ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "handleConfirm" } },
                            [_vm._v("Xác nhận\n          ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "handleDelete" } },
                            [_vm._v("Xóa đơn\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.absentlist,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                "cell-style": _vm.tableRowStyle,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "kidName", label: "Họ và tên", width: "170" },
              }),
              _c("el-table-column", {
                attrs: { prop: "className", label: "Lớp", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { label: "Ngày gửi", width: "135", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(scope.row.createdDate)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "schoolFeedback",
                  label: "Phản hồi",
                  width: "140",
                  align: "center ",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "absentContent",
                  label: "Nội dung",
                  "min-width": "250",
                  align: "left ",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Ngày nghỉ", width: "240", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.fromDate))
                          ),
                        ]),
                        _c("span", [_vm._v(" đến ")]),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(scope.row.toDate))
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewDayLeave(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n            Xem chi tiết\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Quá hạn", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.expired
                          ? _c("span", [_vm._v("Có")])
                          : _c("span", [_vm._v("Không")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "numberFile",
                  label: "Số file",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "Trạng thái",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.confirmStatus
                          ? _c("span", [_vm._v("Đã xác nhận")])
                          : _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("Chưa xác nhận"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  width: "110",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewDetail(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("Chi tiết\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "paging-click row-data",
          staticStyle: { float: "right", "margin-bottom": "10px" },
        },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pageNumber,
              "page-sizes": _vm.$pageListDefaultNew,
              "page-size": _vm.maxPageItem,
              layout: _vm.$pageLayoutDefault,
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("ViewTakeLeave", {
        ref: "ViewTakeLeave",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseShowMethod()
          },
        },
      }),
      _c("ViewDetailDayLeave", {
        ref: "ViewDetailDayLeave",
        attrs: { dialogVisible: _vm.showViewDetailDayLeave },
        on: {
          "dialog-close": function ($event) {
            _vm.showViewDetailDayLeave = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }